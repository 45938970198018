import DropdownButton from 'components/book/options/DropdownButton';
import OptionsMenu from 'components/book/options/OptionsMenu';
import TwoButtonGroup from 'components/TwoButtonGroup';
import BookItemOptions from 'containers/BookItemOptions/BookItemOptions';
import { BookPartsFragment, ReadingStatus } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useMyReadingStateByBook } from 'hooks/useReadingStates';
import useUpdateReadingstate from 'hooks/useUpdateReadingstate';
import Link from 'next/link';
import { Button, Tooltip } from 'ui/generic';
import { routes } from 'utils/routes';

type Props = {
  book: BookPartsFragment;
  dropdownZLayer?: 'Dropdown' | 'Header' | 'Modal';
  mini?: boolean;
  isOpen?: boolean;
  setOpen?: (newVal: boolean) => void;
};

const BookControls = ({ book, dropdownZLayer, mini, isOpen, setOpen }: Props): JSX.Element => {
  const { profile, isSignedIn } = useAuthContext();
  const readingState = useMyReadingStateByBook({ bookId: book.id, workId: book.workId });
  const { updateReadingState } = useUpdateReadingstate();

  if (!isSignedIn || !profile) {
    return (
      <Tooltip
        enableOnNonHoverDevices
        text="Sign up to use"
        render={(ref) => (
          <div ref={ref}>
            <Link href={routes.register}>
              <a>
                <TwoButtonGroup>
                  <Button mini={mini}>Want to read</Button>
                  <OptionsMenu positioningStrategy={'fixed'} button={<DropdownButton mini={mini} />}>
                    {}
                  </OptionsMenu>
                </TwoButtonGroup>
              </a>
            </Link>
          </div>
        )}
      ></Tooltip>
    );
  }

  return (
    <TwoButtonGroup>
      {(!readingState || (readingState && readingState.status === ReadingStatus.None)) && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateReadingState(ReadingStatus.WantsToRead, book, readingState);
          }}
          mini={mini}
        >
          Want to read
        </Button>
      )}
      <BookItemOptions
        book={book}
        positioningStrategy="fixed"
        buttonStyle="arrow"
        zLayer={dropdownZLayer}
        mini={mini}
        isOpen={isOpen}
        setOpen={setOpen}
      />
    </TwoButtonGroup>
  );
};

export default BookControls;
