import classnames from 'classnames';
import { BookPartsFragment } from 'generated/graphql';
import { useLongPress } from 'hooks/useLongPress';
import Link from 'next/link';
import { ReactNode } from 'react';
import { Cover } from 'ui/generic';
import { noop } from 'utils/noop';
import styles from './ItemFeed.module.scss';

export type BookItemFeedProps = {
  book: BookPartsFragment;
  moments?: ReactNode;
  actions?: ReactNode;
  href?: string;
  as?: string;
  onLongPress?: () => void;
  noBorder?: boolean;
};

const ItemFeed = ({
  book,
  actions,
  href,
  as,
  onLongPress = noop,
  noBorder,
}: BookItemFeedProps): JSX.Element => {
  const longPress = useLongPress(onLongPress);

  return (
    <div className={classnames(styles.container, { [styles.noBorder]: noBorder })} {...longPress()}>
      <Link href={href || '/book/[bookSlug]'} as={as || `/book/${book.slug}`}>
        <a draggable="false">
          <div className={styles.inner}>
            <div className={styles.coverOuter}>
              <div className={styles.coverInner}>
                <Cover book={book} />
              </div>
            </div>
            <div className={styles.bookInfo}>
              <span className={styles.title}>
                <h3 className="sectionHeader">{book.title}</h3>
              </span>
              <span className={styles.authors}>{book.authors.map((a) => a.name).join(', ')}</span>
              {actions && <div className={styles.actions}>{actions}</div>}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ItemFeed;
