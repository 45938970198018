import { Activity } from 'components/activity/Activity';
import BookItem from 'components/book/ItemFeed';
import BookControls from 'containers/BookControls';
import { BookShare } from 'containers/share/BookShare';
import ShareContentButton from 'containers/share/ShareContentButton';
import {
  ActivityGroupPartsFragment,
  BookInClubActivityFullFragment,
  DiscoveryActorType,
} from 'generated/graphql';
import { DiscoveryContextProvider } from 'hooks/useDiscoveryContext';
import React, { useState } from 'react';
import { routes } from 'utils/routes';
import { getSharableBookImageUrl } from 'utils/shareMedia';
import { isBookInClubActivity, notEmpty } from 'utils/typeGuards';
import { ActivityReactionBar } from './ActivityReactionBar';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: Array<BookInClubActivityFullFragment>;
  };
  isInClubTimeline?: boolean;
  avatarSticker?: JSX.Element;
};

export const BookInClubActivity = ({
  activityGroup,
  isInClubTimeline,
  avatarSticker,
}: Props): JSX.Element | null => {
  const [isOpenDropdown, setOpenDropdown] = useState<string>();
  const activities = activityGroup.activities?.filter(notEmpty) || [];
  if (!activityGroup || !activities) return null;
  if (activities.length === 0) return null;

  const firstActivity = activities[0];
  if (!firstActivity?.data) return null;

  const club = firstActivity.data.club;
  const profile = firstActivity.data.addedByProfile;

  if (!profile) return null;

  const collapsable = {
    buttonText: `Show all ${activityGroup.activity_count} books`,
    height: 480,
    minItems: 3,
  };

  const segments = [
    { text: profile.name, href: routes.profile, as: `/${profile.handle}` },
    { text: club.name, href: routes.club, as: `/club/${club.handle}` },
    ...(isInClubTimeline ? [{ text: `in ${club.name}`, remove: true }] : []),
  ];

  return (
    <DiscoveryContextProvider
      mediatorHandle={profile.handle}
      mediatorId={profile.id}
      mediatorType={DiscoveryActorType.Profile}
    >
      <Activity
        collapsable={collapsable}
        profile={profile}
        segments={segments}
        activityGroup={activityGroup}
        avatarSticker={avatarSticker}
        renderContent={(group) => (
          <>
            {group.activities.map((a, i) => {
              if (a.data && isBookInClubActivity(a)) {
                const bookUrl = `/book/${a.data.book.slug}`;
                return (
                  <div key={a.id}>
                    {i > 0 && <div className="mt-8" />}
                    <BookItem
                      key={a.id}
                      book={a.data.book}
                      href={routes.globalBook}
                      as={bookUrl}
                      onLongPress={() => setOpenDropdown(a.data?.bookId)}
                      actions={
                        <BookControls
                          book={a.data.book}
                          mini
                          isOpen={(a.data.bookId && isOpenDropdown === a.data.bookId) || false}
                          setOpen={(newVal) => {
                            newVal ? setOpenDropdown(a.data?.bookId) : setOpenDropdown(undefined);
                          }}
                        />
                      }
                    />

                    <div className="mt-4">
                      <ActivityReactionBar
                        shareButton={
                          <ShareContentButton
                            reactionBarButton
                            shareImageUrl={(format) =>
                              a.data && getSharableBookImageUrl(a.data.book.slug, format)
                            }
                            renderImagePreview={<BookShare book={a.data.book} />}
                            shareInfo={{
                              path: bookUrl,
                            }}
                          />
                        }
                        activity={a}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
      />
    </DiscoveryContextProvider>
  );
};
