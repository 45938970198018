import { Activity } from 'components/activity/Activity';
import { ActivityHeadlineSegment } from 'components/activity/ActivityHeadline';
import BookItem from 'components/book/ItemFeed';
import BookControls from 'containers/BookControls';
import { BookShare } from 'containers/share/BookShare';
import ShareContentButton from 'containers/share/ShareContentButton';
import {
  ActivityGroupPartsFragment,
  BookOnShelfActivityFullFragment,
  DiscoveryActorType,
} from 'generated/graphql';
import { DiscoveryContextProvider } from 'hooks/useDiscoveryContext';
import { useMemo, useState } from 'react';
import { routes } from 'utils/routes';
import { getSharableBookImageUrl } from 'utils/shareMedia';
import { isBookOnShelfActivity, notEmpty } from 'utils/typeGuards';
import { ActivityReactionBar } from './ActivityReactionBar';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: Array<BookOnShelfActivityFullFragment>;
  };
  avatarSticker?: JSX.Element;
};

export const BookOnShelfActivity = ({ activityGroup, avatarSticker }: Props): JSX.Element | null => {
  const [isOpenDropdown, setOpenDropdown] = useState<string>();
  if (!activityGroup.activities) return null;
  const firstActivity = activityGroup.activities[0];
  if (!firstActivity?.data) return null;

  const profile = firstActivity.data.shelf.owner;

  const collapsable = useMemo(() => {
    return { buttonText: `Show all ${activityGroup.activity_count} books`, height: 480, minItems: 3 };
  }, [activityGroup]);

  const segments = useMemo(() => {
    const result: ActivityHeadlineSegment[] = [
      { text: profile.name, href: routes.profile, as: `/${profile.handle}` },
    ];

    activityGroup.activities
      .map((activity) => activity.data?.shelf)
      .filter(notEmpty)
      .forEach((shelf) => {
        result.push({ text: shelf.title, href: routes.shelf, as: `/${profile.handle}/shelf/${shelf.slug}` });
      });

    return result;
  }, [activityGroup, profile]);

  // remove duplicate activities by book id
  const group = useMemo(() => {
    const copy = { ...activityGroup };
    copy.activities = copy.activities.filter((activity, index) => {
      const isDuplicate =
        copy.activities.findIndex((otherActivity) => {
          return otherActivity.data?.book?.id === activity.data?.book?.id;
        }) !== index;
      return !isDuplicate;
    });
    return copy;
  }, [activityGroup]);

  return (
    <DiscoveryContextProvider
      mediatorHandle={profile.handle}
      mediatorId={profile.id}
      mediatorType={DiscoveryActorType.Profile}
    >
      <Activity
        avatarSticker={avatarSticker}
        collapsable={collapsable}
        profile={profile}
        segments={segments}
        activityGroup={group}
        renderContent={(group) => (
          <>
            {group.activities.map((a, i) => {
              if (a.data && isBookOnShelfActivity(a)) {
                const bookUrl = `/book/${a.data.book.slug}`;
                return (
                  <div key={a.id}>
                    {i > 0 && <div className="mt-8" />}
                    <BookItem
                      key={a.id}
                      book={a.data.book}
                      href={routes.globalBook}
                      as={bookUrl}
                      onLongPress={() => setOpenDropdown(a.data?.bookId)}
                      actions={
                        <BookControls
                          book={a.data.book}
                          mini
                          isOpen={(a.data.bookId && isOpenDropdown === a.data.bookId) || false}
                          setOpen={(newVal) => {
                            newVal ? setOpenDropdown(a.data?.bookId) : setOpenDropdown(undefined);
                          }}
                        />
                      }
                    />
                    <div className="mt-4">
                      <ActivityReactionBar
                        shareButton={
                          <ShareContentButton
                            reactionBarButton
                            shareImageUrl={(format) =>
                              a.data && getSharableBookImageUrl(a.data.book.slug, format)
                            }
                            renderImagePreview={<BookShare book={a.data.book} />}
                            shareInfo={{
                              path: `/activity/${a.id}`,
                            }}
                          />
                        }
                        activity={a}
                      />
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
      />
    </DiscoveryContextProvider>
  );
};
