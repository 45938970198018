import classnames from 'classnames';
import { useMemo } from 'react';
import styles from './GoalProgress.module.scss';

type Size = 'large' | 'medium' | 'small' | 'extra-large';
type Variant = 'default' | 'progress';

type Props = {
  read?: number;
  target?: number;
  size?: Size;
  variant?: Variant;
  width?: number;
};

const goalRadiusSizes = {
  extraLarge: 165,
  large: 102.5,
  medium: 72.5,
  small: 24,
};

export const GoalProgress = ({
  read = 0,
  target,
  size = 'medium',
  variant = 'progress',
  width,
}: Props): JSX.Element | null => {
  const radius = useMemo(() => {
    if (width) return width / 2;
    if (size === 'large') {
      return goalRadiusSizes.large;
    } else if (size === 'small') {
      return goalRadiusSizes.small;
    } else if (size === 'extra-large') {
      return goalRadiusSizes.extraLarge;
    }
    return goalRadiusSizes.medium;
  }, [size]);

  if (variant === 'default') {
    return (
      <div
        style={{ width: radius * 2, height: radius * 2 }}
        className={classnames(styles.progress, styles.default)}
      >
        <div className={styles.data}>
          <span className={classnames(styles.readCount, styles[size])}>{target}</span>
          {size !== 'small' && <span className={classnames(styles.target)}>books</span>}
        </div>
      </div>
    );
  }

  read = Math.max(read, 0);
  if (!target) return <div style={{ height: radius * 2 }}></div>;
  const progress = read >= target ? 100 : (100 / target) * read;
  const stroke = size === 'small' ? 2 : 3;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const goalComplete = read >= target;
  const primaryFill = goalComplete ? 'var(--accentPrimary)' : 'transparent';

  return (
    <div style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }} className={styles.progress}>
      <svg className={styles.svg} height={radius * 2} width={radius * 2}>
        <circle
          className={styles.circle}
          stroke="var(--uiInput)"
          fill="transparent"
          strokeWidth={stroke - 1}
          strokeDasharray={circumference + ' ' + circumference}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={styles.circle}
          stroke="var(--accentPrimary)"
          fill={primaryFill}
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>

      <div className={classnames(styles.data, { [styles.complete]: goalComplete })}>
        <span className={classnames(styles.readCount, styles[size], { [styles.complete]: goalComplete })}>
          {read}
        </span>
        {size !== 'small' && (
          <span
            className={classnames(styles.target, {
              [styles.complete]: goalComplete,
              [styles.largeText]: size === 'extra-large',
            })}
          >
            of {target} books
          </span>
        )}
      </div>
    </div>
  );
};

export default GoalProgress;
