import { forwardRef, ReactNode } from 'react';
import styles from './TwoButtonGroup.module.scss';

type Props = {
  children: ReactNode;
};

const TwoButtonGroup = forwardRef<HTMLDivElement, Props>(
  ({ children }: Props, ref): JSX.Element => {
    return (
      <div className={styles.group} ref={ref}>
        {children}
      </div>
    );
  }
);

TwoButtonGroup.displayName = 'TwoButtonGroup';

export default TwoButtonGroup;
