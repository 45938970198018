import { CompleteGoalActivityFullFragment, CreateGoalActivityFullFragment } from 'generated/graphql';
import styles from './ActivityContent.module.scss';
import GoalDisplayDate from './GoalDisplayDate';
import GoalFocus from './GoalFocus';
import { GoalProgress } from './GoalProgress';

type CreateGoalProps = {
  activity: CreateGoalActivityFullFragment;
};

export const CreateGoalActivityContent = ({ activity }: CreateGoalProps): JSX.Element | null => {
  if (!activity.data) return null;
  return (
    <div className={styles.container}>
      <GoalProgress target={activity.data.target} variant="default" />
      <div className={styles.text}>
        <GoalDisplayDate fromDate={activity.data.startDate} toDate={activity.time || activity.data.endDate} />
        <h3 className="sectionHeader">{activity.data.name}</h3>
      </div>
    </div>
  );
};

type CompleteGoalProps = {
  activity: CompleteGoalActivityFullFragment;
};

export const CompleteGoalActivityContent = ({ activity }: CompleteGoalProps): JSX.Element | null => {
  if (!activity.data) return null;
  return <GoalFocus progress={activity.data.participant?.progress} goal={activity.data} />;
};
