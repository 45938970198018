import classnames from 'classnames';
import { Rating } from 'components/basics/Rating';
import { ReviewTag } from 'components/book/review/ReviewTag';
import { getTagsIn } from 'components/book/review/ReviewTags';
import { BrandMark } from 'components/layout/shared/Brand';
import { BookPartsFragment, ProfilePartsFragment, ReviewPartsFragment } from 'generated/graphql';
import { ShareFormat, useGetShareFormat } from 'hooks/useGetShareFormat';
import { useMemo } from 'react';
import { Avatar, Cover } from 'ui/generic';
import styles from './ReviewShare.module.scss';

type Props = {
  review: ReviewPartsFragment;
  book: BookPartsFragment;
  profile: ProfilePartsFragment;
  shareFormat?: ShareFormat;
};

export const ReviewShare = ({ review, book, profile, shareFormat }: Props): JSX.Element => {
  const sharingFormat = useGetShareFormat(shareFormat);

  const tags = useMemo(() => {
    if (!review.tags) return [];
    return getTagsIn(review.tags);
  }, [review.tags]);

  return (
    <div
      className={classnames(styles.container, {
        [styles.square]: sharingFormat === 'square',
        [styles.portrait]: sharingFormat === 'portrait',
      })}
    >
      <div className={styles.left}>
        <div className={styles.logo}>
          <BrandMark />
        </div>
        <div className={styles.cover}>
          <Cover book={book} assetSize="600" />
          <div className={styles.avatar}>
            <Avatar profile={profile} width={78} />
          </div>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.text}>
          <h1 className="sectionHeader italic" id={review.id}>
            {profile.name}&apos;s review of
          </h1>
          <br />
          <h1 className="sectionHeader" style={{ marginBottom: 10 }}>
            <span className={styles.title}>{book.title}</span>
            {book.authors && book.authors.length > 0 && (
              <>
                <i> by </i>
                {book.authors[0].name}
              </>
            )}
          </h1>
        </div>
        <div className={styles.rating}>
          {review.rating > 0 && <Rating size={80} initValue={review.rating} className="my-4" />}
        </div>
        <div className={styles.tagsContainer}>
          {tags &&
            tags.length > 0 &&
            tags.slice(0, 2).map((tag) => <ReviewTag key={tag.value} tag={tag} isActive={true} />)}
        </div>
      </div>
    </div>
  );
};
