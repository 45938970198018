import classNames from 'classnames';
import ResizableElement from 'components/basics/ResizableElement';
import GoalProgress from 'components/goal/GoalProgress';
import { BrandMark } from 'components/layout/shared/Brand';
import { GoalParticipantPartsFragment, GoalPartsFragment } from 'generated/graphql';
import { ShareFormat, useGetShareFormat } from 'hooks/useGetShareFormat';
import { Avatar } from 'ui/generic';
import styles from './GoalShare.module.scss';

type Props = {
  goalParticipant: GoalParticipantPartsFragment;
  goal: GoalPartsFragment;
  shareFormat?: ShareFormat;
};

export const GoalShare = ({ goalParticipant, goal, shareFormat }: Props): JSX.Element => {
  const sharingFormat = useGetShareFormat(shareFormat);

  const goalCompleted = goalParticipant.progress >= goal.target;

  return (
    <div
      className={classNames(styles.container, {
        [styles.completed]: goalCompleted,
        [styles.square]: sharingFormat === 'square',
        [styles.portrait]: sharingFormat === 'portrait',
      })}
    >
      <div className={styles.top}>
        <div className={styles.logo}>
          <BrandMark />
        </div>
        <div className={styles.avatar}>
          <Avatar profile={goalParticipant.profile} width={80} />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.progress}>
          <ResizableElement
            maxHeight={sharingFormat === 'portrait' ? 673 : undefined}
            initialWidth={330}
            initialHeight={330}
          >
            <GoalProgress size="extra-large" target={goal.target} read={goalParticipant.progress} />
          </ResizableElement>
        </div>
        <div className={styles.goalInfo}>
          {sharingFormat === 'landscape' && (
            <span className={styles.goalHeader}>{goalCompleted ? 'Reached goal' : 'Goal'}</span>
          )}
          <span className={styles.name}>{goal.name}</span>
        </div>
      </div>
    </div>
  );
};

export default GoalShare;
