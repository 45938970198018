import { PatronBadgeSmall } from 'components/badges/PatronBadge';
import { useIsSupporter } from 'hooks/useIsSupporter';
import { ActivityGroupFull } from 'types/activity';
import {
  isBookInClubGroup,
  isBookOnShelfGroup,
  isBookReviewGroup,
  isClubPostGroup,
  isCompleteGoalGroup,
  isCreateGoalGroup,
  isMomentGroup,
  isReadingStateGroup,
} from 'utils/typeGuards';
import { BookInClubActivity } from './BookInClubActivity';
import { BookOnShelfActivity } from './BookOnShelfActivity';
import { BookReviewActivity } from './BookReviewActivity';
import { ClubPostActivity } from './ClubPostActivity';
import { CompleteGoalActivity } from './CompleteGoalActivity';
import { CreateGoalActivity } from './CreateGoalActivity';
import { MomentActivity } from './MomentActivity';
import { ReadingStateActivity } from './ReadingStateActivity';

type Props = {
  activityGroup: ActivityGroupFull;
  isInClubTimeline?: boolean;
  avatarSticker?: JSX.Element;
};

export const ActivityGroup = ({
  activityGroup,
  isInClubTimeline,
  avatarSticker,
}: Props): JSX.Element | null => {
  const isSupporter = useIsSupporter({ profileId: activityGroup.activities[0].actor || undefined });

  if (!avatarSticker && isSupporter) {
    avatarSticker = <PatronBadgeSmall />;
  }

  if (isMomentGroup(activityGroup)) {
    return <MomentActivity avatarSticker={avatarSticker} activityGroup={activityGroup} />;
  } else if (isReadingStateGroup(activityGroup)) {
    return <ReadingStateActivity avatarSticker={avatarSticker} activityGroup={activityGroup} />;
  } else if (isBookReviewGroup(activityGroup)) {
    return <BookReviewActivity avatarSticker={avatarSticker} activityGroup={activityGroup} />;
  } else if (isBookOnShelfGroup(activityGroup)) {
    return <BookOnShelfActivity avatarSticker={avatarSticker} activityGroup={activityGroup} />;
  } else if (isBookInClubGroup(activityGroup)) {
    return (
      <BookInClubActivity
        avatarSticker={avatarSticker}
        activityGroup={activityGroup}
        isInClubTimeline={isInClubTimeline}
      />
    );
  } else if (isClubPostGroup(activityGroup)) {
    // For now we dont show ClubPosts in club's timeline anymore
    // TODO: Make clubTimeline unfollow club...
    if (isInClubTimeline) return null;
    return (
      <ClubPostActivity
        isInClubTimeline={isInClubTimeline}
        avatarSticker={avatarSticker}
        activityGroup={activityGroup}
      />
    );
  } else if (isCreateGoalGroup(activityGroup)) {
    return <CreateGoalActivity activityGroup={activityGroup} />;
  } else if (isCompleteGoalGroup(activityGroup)) {
    return <CompleteGoalActivity activityGroup={activityGroup} />;
  }

  return null;
};
