import { Activity } from 'components/activity/Activity';
import { CompleteGoalActivityContent } from 'components/goal/ActivityContent';
import GoalShare from 'containers/share/GoalShare';
import { ShareContentButton } from 'containers/share/ShareContentButton';
import { dayjs } from 'date-utils';
import { ActivityGroupPartsFragment, CompleteGoalActivityFullFragment } from 'generated/graphql';
import Link from 'next/link';
import { routes } from 'utils/routes';
import { getSharableGoalImageUrl } from 'utils/shareMedia';
import { isCompleteGoalActivity, notEmpty } from 'utils/typeGuards';
import { ActivityReactionBar } from './ActivityReactionBar';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: Array<CompleteGoalActivityFullFragment>;
  };
};

export const CompleteGoalActivity = ({ activityGroup }: Props) => {
  const activities = activityGroup.activities?.filter(notEmpty) || [];
  if (!activityGroup || !activities) return null;
  if (activities.length === 0) return null;
  const firstActivity = activities[0];
  if (!firstActivity?.data) return null;
  const profile = firstActivity.data.participant?.profile;
  if (!profile) return null;

  // Using the activity group's last date instead of the goal's creation date
  const date = dayjs.utc(activityGroup.updated_at).toISOString();

  const collapsable = {
    buttonText: `Show all`,
    height: 480,
    minItems: 3,
  };
  const segments = [{ text: profile.name, href: routes.profile, as: `/${profile.handle}` }];

  return (
    <Activity
      collapsable={collapsable}
      profile={profile}
      segments={segments}
      activityGroup={activityGroup}
      dateOverride={date}
      renderContent={(group) => (
        <>
          {group.activities.map((a, i) => {
            if (a.data && isCompleteGoalActivity(a)) {
              const goalUrl = `/${a.data.createdBy.handle}/goal/${a.data.slug}`;
              return (
                <div key={a.id}>
                  {i > 0 && <div className="mt-8" />}
                  <Link href={routes.goal} as={goalUrl}>
                    <a>
                      <CompleteGoalActivityContent activity={a} />
                    </a>
                  </Link>
                  <div className="mt-4">
                    <ActivityReactionBar
                      activity={a}
                      shareButton={
                        <ShareContentButton
                          reactionBarButton
                          shareImageUrl={(format) =>
                            a.data &&
                            a.data.participant &&
                            getSharableGoalImageUrl(
                              a.data.createdBy.handle,
                              a.data.slug,
                              a.data.participant.progress,
                              format
                            )
                          }
                          renderImagePreview={
                            a.data.participant && (
                              <GoalShare goalParticipant={a.data.participant} goal={a.data} />
                            )
                          }
                          shareInfo={{
                            path: `/activity/${a.id}`,
                          }}
                        />
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
        </>
      )}
    />
  );
};
