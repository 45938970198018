import { Activity } from 'components/activity/Activity';
import { BookReview } from 'components/book/review/Review';
import BookControls from 'containers/BookControls';
import { ReviewShare } from 'containers/share/ReviewShare';
import ShareContentButton from 'containers/share/ShareContentButton';
import {
  ActivityGroupPartsFragment,
  BookReviewActivityFullFragment,
  DiscoveryActorType,
} from 'generated/graphql';
import { DiscoveryContextProvider } from 'hooks/useDiscoveryContext';
import React, { useState } from 'react';
import { routes } from 'utils/routes';
import { getSharableReviewImageUrl } from 'utils/shareMedia';
import { isBookReviewActivity } from 'utils/typeGuards';
import { ActivityReactionBar } from './ActivityReactionBar';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: Array<BookReviewActivityFullFragment>;
  };
  avatarSticker?: JSX.Element;
};

export const BookReviewActivity = ({ activityGroup, avatarSticker }: Props): JSX.Element | null => {
  const [isOpenDropdown, setOpenDropdown] = useState<string>();
  const { activities } = activityGroup;
  if (!activities || activities.length === 0) return null;

  if (activities.length === 0) return null;
  const firstActivity = activities[0];
  if (!firstActivity.data) return null;

  const profile = firstActivity.data.profile;

  const collapsable = {
    buttonText: `Show all ${activityGroup.activity_count} books`,
    height: 480,
    minItems: 2,
  };

  const segments = [{ text: profile.name, href: routes.profile, as: `/${profile.handle}` }];

  return (
    <DiscoveryContextProvider
      mediatorHandle={profile.handle}
      mediatorId={profile.id}
      mediatorType={DiscoveryActorType.Profile}
    >
      <Activity
        collapsable={collapsable}
        profile={profile}
        segments={segments}
        activityGroup={activityGroup}
        avatarSticker={avatarSticker}
        renderContent={(group) => (
          <>
            {group.activities.map((a, i) => {
              if (a.data && isBookReviewActivity(a)) {
                return (
                  <div key={a.id}>
                    {i > 0 && <div className="mt-8" />}
                    <BookReview
                      profile={a.data.profile}
                      review={a.data}
                      book={a.data.book}
                      showBookCover={true}
                      linkBookCover={true}
                      onLongPress={() => setOpenDropdown(a.data?.book.id)}
                      button={
                        <div className="my-6">
                          <BookControls
                            mini
                            book={a.data.book}
                            isOpen={(a.data.book.id && isOpenDropdown === a.data.book.id) || false}
                            setOpen={(newVal) => {
                              newVal ? setOpenDropdown(a.data?.book.id) : setOpenDropdown(undefined);
                            }}
                          />
                        </div>
                      }
                    />
                    <div className="mt-4">
                      <ActivityReactionBar
                        activity={a}
                        shareButton={
                          <ShareContentButton
                            reactionBarButton
                            shareImageUrl={(format) => a.data && getSharableReviewImageUrl(a.data.id, format)}
                            renderImagePreview={
                              <ReviewShare book={a.data.book} review={a.data} profile={a.data.profile} />
                            }
                            shareInfo={{
                              path: `/activity/${a.id}`,
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
      />
    </DiscoveryContextProvider>
  );
};
