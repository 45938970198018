import { GoalPartsFragment } from 'generated/graphql';
import GoalDisplayDate from './GoalDisplayDate';
import styles from './GoalFocus.module.scss';
import GoalProgress from './GoalProgress';

type Props = {
  goal: GoalPartsFragment;
  progress?: number;
};

const GoalFocus = ({ goal, progress }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <GoalProgress read={progress} target={goal.target} variant="progress" />
      <div className={styles.text}>
        <GoalDisplayDate fromDate={goal.startDate} toDate={goal.endDate} />
        <h3 className="sectionHeader">{goal.name}</h3>
      </div>
    </div>
  );
};

export default GoalFocus;
