import { dayjs, isSameMonth, isSameYear } from 'date-utils';
import { ArrowRightIcon } from 'ui/icons';
import styles from './GoalDisplayDate.module.scss';

type Props = {
  fromDate: Date;
  toDate: Date;
};

const GoalDisplayDate = ({ fromDate, toDate }: Props): JSX.Element => {
  const sameYear = isSameYear(fromDate, toDate);
  const sameMonth = isSameMonth(fromDate, toDate);
  const fromDayOfYear = dayjs(fromDate).dayOfYear();
  const toDayOfYear = dayjs(toDate).dayOfYear();
  if (sameYear && fromDayOfYear === 1 && (toDayOfYear === 365 || toDayOfYear === 366)) {
    const year = dayjs(fromDate).get('year');
    return <div className={styles.container}>{year}</div>;
  } else if (sameMonth && toDayOfYear - fromDayOfYear > 25) {
    const month = dayjs(fromDate).format('MMMM');
    const year = dayjs(fromDate).get('year');
    return (
      <div className={styles.container}>
        {month}
        <span className="ml-1">{year}</span>
      </div>
    );
  } else
    return (
      <div className={styles.container}>
        <span className="mr-2">{dayjs(fromDate).format('DD/MM/YYYY')}</span>
        <ArrowRightIcon />
        <span className="ml-2">{dayjs(toDate).format('DD/MM/YYYY')}</span>
      </div>
    );
};

export default GoalDisplayDate;
