import classnames from 'classnames';
import { BrandMark } from 'components/layout/shared/Brand';
import { dayjs } from 'date-utils';
import { BookPartsFragment } from 'generated/graphql';
import { ShareFormat, useGetShareFormat } from 'hooks/useGetShareFormat';
import { Cover } from 'ui/generic';
import styles from './BookShare.module.scss';

type Props = {
  book: BookPartsFragment;
  shareFormat?: ShareFormat;
};

export const BookShare = ({ book, shareFormat }: Props): JSX.Element => {
  // todo: maybe use later
  // const style = {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   ['--gradient' as any]: getGradient(book.gradientColors?.filter(notEmpty)),
  // };

  const sharingFormat = useGetShareFormat(shareFormat);

  const date = book.publishedDate ? dayjs(book.publishedDate).format('YYYY') : null;

  return (
    <div
      className={classnames(styles.container, {
        [styles.square]: sharingFormat === 'square',
        [styles.portrait]: sharingFormat === 'portrait',
      })}
    >
      <div className={styles.logo}>
        <BrandMark />
      </div>
      <div className={styles.book}>
        <Cover book={book} stretch="vertical" assetSize="600" />
      </div>
      <div className={styles.text}>
        <h1 className={classnames(styles.title, 'displayHeader')}>{book.title}</h1>
        {sharingFormat === 'portrait' && (
          <h1 className={classnames(styles.subtitle, 'displayHeader')}>{book.subtitle}</h1>
        )}
        <p className={styles.authors}>
          {book.authors
            .slice(0, 3)
            .map((a) => a.name)
            .join(', ')}
          {date && ` — ${date}`}
        </p>
      </div>
    </div>
  );
};
