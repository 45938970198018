import { Activity } from 'components/activity/Activity';
import BookItem from 'components/book/ItemFeed';
import BookControls from 'containers/BookControls';
import { BookShare } from 'containers/share/BookShare';
import { ShareContentButton } from 'containers/share/ShareContentButton';
import {
  ActivityGroupPartsFragment,
  DiscoveryActorType,
  ReadingStateActivityFullFragment,
} from 'generated/graphql';
import { DiscoveryContextProvider } from 'hooks/useDiscoveryContext';
import { useState } from 'react';
import { statesData } from 'utils/readingStates';
import { routes } from 'utils/routes';
import { getSharableBookImageUrl } from 'utils/shareMedia';
import { isReadingStateActivity } from 'utils/typeGuards';
import { ActivityReactionBar } from './ActivityReactionBar';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: ReadingStateActivityFullFragment[];
  };
  avatarSticker?: JSX.Element;
};

export const ReadingStateActivity = ({ activityGroup, avatarSticker }: Props): JSX.Element | null => {
  const [isOpenDropdown, setOpenDropdown] = useState<string>();
  const activities = activityGroup.activities.filter(filterOutdatedReadingStates) || [];

  if (activities.length === 0) return null;
  const firstActivity = activities[0];
  const profile = firstActivity.data?.profile;
  if (!firstActivity.data || !profile || !firstActivity.status) return null;

  const status = firstActivity.status;
  const stateInfo = statesData[status];

  const collapsable = {
    buttonText: `Show all ${activityGroup.activity_count} books`,
    height: 480,
    minItems: 3,
  };

  const segments = [
    { text: firstActivity.data.book.title, remove: true },
    { text: profile.name, href: routes.profile, as: `/${profile.handle}` },
    {
      text: `${activityGroup.activity_count} books`,
      href: stateInfo.slug,
      as: `/${profile.handle}/${stateInfo.slug}`,
    },
  ];

  return (
    <DiscoveryContextProvider
      mediatorHandle={profile.handle}
      mediatorId={profile.id}
      mediatorType={DiscoveryActorType.Profile}
    >
      <Activity
        avatarSticker={avatarSticker}
        collapsable={collapsable}
        profile={profile}
        segments={segments}
        activityGroup={activityGroup}
        renderContent={(group) => (
          <>
            {group.activities.map((a, i) => {
              if (a.data && isReadingStateActivity(a)) {
                const bookUrl = `/book/${a.data.book.slug}`;
                return (
                  <div key={a.id}>
                    {i > 0 && <div className="mt-8" />}
                    <BookItem
                      book={a.data.book}
                      href={routes.globalBook}
                      as={bookUrl}
                      onLongPress={() => setOpenDropdown(a.data?.bookId)}
                      actions={
                        <BookControls
                          book={a.data.book}
                          mini
                          isOpen={(a.data.bookId && isOpenDropdown === a.data.bookId) || false}
                          setOpen={(newVal) => {
                            newVal ? setOpenDropdown(a.data?.bookId) : setOpenDropdown(undefined);
                          }}
                        />
                      }
                    />
                    <div className="mt-4">
                      <ActivityReactionBar
                        activity={a}
                        shareButton={
                          <ShareContentButton
                            reactionBarButton
                            shareImageUrl={(format) =>
                              a.data && getSharableBookImageUrl(a.data.book.slug, format)
                            }
                            renderImagePreview={a.data && <BookShare book={a.data.book} />}
                            shareInfo={{ path: bookUrl }}
                          />
                        }
                      />
                    </div>
                  </div>
                );
              }
            })}
          </>
        )}
      />
    </DiscoveryContextProvider>
  );
};

const filterOutdatedReadingStates = (activity: ReadingStateActivityFullFragment) => {
  // Filter activities with missing readingState
  // (not sure how this can happen, but it does)
  if (!activity.data) {
    return false;
  }
  return true;
};
