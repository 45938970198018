import { Activity } from 'components/activity/Activity';
import { ActivityHeadlineSegment } from 'components/activity/ActivityHeadline';
import { ActivityGroupPartsFragment, ClubPostActivityFullFragment } from 'generated/graphql';
import { routes } from 'utils/routes';
import { isClubPostActivity } from 'utils/typeGuards';
import { ClubPost } from '../Clubs/ClubPost';

type Props = {
  activityGroup: ActivityGroupPartsFragment & {
    activities: Array<ClubPostActivityFullFragment>;
  };
  isInClubTimeline?: boolean;
  avatarSticker?: JSX.Element;
};

export const ClubPostActivity = ({
  activityGroup,
  isInClubTimeline,
  avatarSticker,
}: Props): JSX.Element | null => {
  const activities = activityGroup.activities;

  if (!activities || activities.length === 0) return null;
  const firstActivity = activities[0];
  if (!firstActivity || !firstActivity.data) return null;

  const profile = firstActivity.data.profile;
  const club = firstActivity.data.club;

  const collapsable = {
    buttonText:
      activityGroup.activity_count === 1 ? `Show more` : `Show all ${activityGroup.activity_count} posts`,
    height: 300,
    minItems: 3,
  };

  const segments: ActivityHeadlineSegment[] = [
    { text: profile.name, href: routes.profile, as: `/${profile.handle}` },
    { text: club.name, href: routes.club, as: `/club/${club.handle}` },
    ...(isInClubTimeline ? [{ text: `in ${club.name}`, remove: true }] : []),
  ];

  return (
    <Activity
      collapsable={collapsable}
      profile={profile}
      segments={segments}
      activityGroup={activityGroup}
      avatarSticker={avatarSticker}
      renderContent={(group) => (
        <>
          {group.activities.map((a, i) => {
            if (a.data && isClubPostActivity(a)) {
              return (
                <div key={i} className={i < group.activities.length - 1 ? 'mb-5' : ''}>
                  <ClubPost clubHandle={club.handle} post={a.data} withoutHeader asLink />
                </div>
              );
            }
          })}
        </>
      )}
    />
  );
};
